import {
  BankaiSEO,
  BaseGrid,
  ContentGrid,
  Header,
  PaymentActions,
} from '../components';

import {
  DashboardBkaiCard,
  DashboardComingCryptos,
  DashboardCryptoWalletList,
  DashboardTokens,
} from '../sections';

const DashboardPage = () => {
  return (
    <>
      <BankaiSEO
        title="Painel da conta Bank.ai"
        description="Gerencie sua conta Bank.ai."
      />
      <BaseGrid>
        <Header />
        <ContentGrid>
          <PaymentActions />
          <DashboardCryptoWalletList />
          <DashboardComingCryptos />
          <DashboardBkaiCard />
          <DashboardTokens />
        </ContentGrid>
      </BaseGrid>
    </>
  );
};

export default DashboardPage;
